.root {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;

  background-color: rgba(0, 0, 0, 0.5);
}

.body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  opacity: 1;
  background-color: white;
  border-radius: 16px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 16px;
}

.title {
  margin: 0;
  font-size: 1.5rem;
  color: black;
  text-align: left;
}

.cancelButton {
  width: 100px;
  height: 30px;

  color: black;
  background-color: transparent;
  margin-right: 10px;
  border: 1px solid black;
  border-radius: 4px;

  cursor: pointer;
}

.confirmButton {
  width: 100px;
  height: 30px;

  color: white;
  background-color: rgb(70, 150, 236);
  border: 1px solid black;
  border-radius: 4px;

  cursor: pointer;
}

.confirmButton:hover {
  background-color: rgb(49, 104, 203);
}

.cancelButton:hover {
  background-color: rgb(202, 202, 202);
}
