.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 10px;

  width: 50px;
  height: 50px;

  border-radius: 8px;
  background: linear-gradient(195deg, rgb(72, 150, 236), rgb(49, 104, 203));
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    rgba(0, 55, 253, 0.509) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.text {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: rgb(123, 128, 154);
}

.statistic {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: rgb(52, 71, 103);
}

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 600px;
  height: 100px;

  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;

  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem,
    rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
  background-color: #f7f7f8;
}
