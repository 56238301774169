.root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.icon {
  color: #fff;
}
